@font-face {
    font-family: OpenSans;
    src: url('../fonts/OpenSans/OpenSans.eot');
    src: url('../fonts/OpenSans/OpenSans.eot') format('embedded-opentype'),
        url('../fonts/OpenSans/OpenSans.woff2') format('woff2'),
        url('../fonts/OpenSans/OpenSans.woff') format('woff'),
        url('../fonts/OpenSans/OpenSans.ttf') format('truetype');
    font-display: block ;
}

@font-face {
    font-family: OpenSansBold;
    src: url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: MaximaEF;
    font-family: 'MaximaEF';
    src: url('../fonts/MaximaEF/MaximaEF-Bold.eot');
    src: url('../fonts/MaximaEF/MaximaEF-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaximaEF/MaximaEF-Bold.woff2') format('woff2'),
        url('../fonts/MaximaEF/MaximaEF-Bold.woff') format('woff'),
        url('../fonts/MaximaEF/MaximaEF-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}
.slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}.slick-dots li button:before,.slick-next:before,.slick-prev:before{font-family:slick;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale} .slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:20px;height:20px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);cursor:pointer;color:transparent;border:none;outline:0;background:0 0}.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{color:transparent;outline:0;background:0 0}.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;color:#fff}.slick-prev{left:-25px}[dir=rtl] .slick-prev{right:-25px;left:auto}.slick-prev:before{content:'←'}.slick-next:before,[dir=rtl] .slick-prev:before{content:'→'}.slick-next{right:-25px}[dir=rtl] .slick-next{right:auto;left:-25px}[dir=rtl] .slick-next:before{content:'←'}.slick-dotted.slick-slider{margin-bottom:30px}.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer}.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0}.slick-dots li button:focus,.slick-dots li button:hover{outline:0}.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}.slick-dots li button:before{font-size:6px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'•';text-align:center;opacity:.25;color:#000}.slick-dots li.slick-active button:before{opacity:.75;color:#000}
html, body{
    overflow-x: hidden;
}
#root{
    height: 100%;
}
.App{
    height: 100%;
}
body {
    margin: 0;
    position: relative;
    font-family: OpenSans;
    
  }

  h1, h2{
    font-family: MaximaEF;
    font-weight: 700;
  }
  h3{
    font-size: 32px;
    margin:0.5em 0;
}
h1 {
    margin: 0;
}
.header {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
p, a{
    transition:0.5s;
    font-family: OpenSans;
}
a {
    display: block;
    color:white;
    text-decoration: none;
    margin:0;
    font-family: OpenSans;
}

div {
    box-sizing: border-box;
}
/* #header > ul > li:hover > p,a {
   transform: scale(1.05);
} */
.activeTab{
    border-bottom: 1px solid white;
}

#header > ul > li:hover {
    border-bottom: 2px solid white;
}
.white{
    background:white;
}
.black{
    background: black;
}
.functionality__cont--withImg{
    background-repeat: no-repeat;
    background-position: top left;
}
.deley_duration{
    transition-delay: 0.25s;
}

.Header__cont{
    position: fixed;
    background: transparent;
    color: white;
    top:0;
    z-index: 5000;
}
.Header__cont > ul {
    display: flex;
    list-style: none;
}
/* .MuiInputLabel-animated{
    color:white !important;
}
.MuiInputBase-input{
    color:white !important;
}
.MuiInput-underline::before{
    border-bottom: 1px solid white !important;
}
.MuiInput-underline:after{
    border-bottom: 1px solid white !important;
}
.MuiInputBase-multiline{
    padding: 6px 0 13px !important;
} */
input {
    cursor: pointer;
}
/* input
    {
    border:none;
    height: 3em;
    margin-top:1em;
    border-bottom:1px solid white;
    color: white;
    width:100%;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
    font-size: 1em;
    transition: 0.3s;
} */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent !important;
    border: 1px solid white !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-box-shadow: transparent !important;
    
}
input:hover{
    transform: scale(1.05);
}

::-webkit-input-placeholder { Edge
    color: white;
  }
  
  :-ms-input-placeholder { Internet Explorer 10-11
    color: white;
  }
  
  ::placeholder {
    color: white;
  }
button {
    cursor: pointer;
} */

.bg_AmniseFirst{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: calc(100vh - 65px);
}
.bg_AmniseFirst{
    position: relative;
    z-index: 2;
    width: 100%;
    top:0;
}
.bg_about{
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}
.bg_Second{
    z-index: 2;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
.contact__cont--img{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
.about__first > div {
    width:100%;
    color: white;
    margin: auto;
}
.navActive {
   background: #3b2b92;
   
}
.navbar {
    color: transparent;
}

.navbar li a {
    color: #083C99;
}

.webinar__info--wrapper {
    display: flex;
    padding-top: 20px;
}

.webinar__info--cont {
    color: white;
}

.webinar__info{
    color: white;
}
.webinar__info > h3 {
    font-weight: bold;
    font-family: OpenSans;
}
.timer__cont{
    display: flex;
    position: relative;
    justify-content: space-between;
    color: white;
    border-top:1px solid white;
    padding: 12px 0;
}
.timer__cont > div {
    width:25%;
    border-right: 1px solid white;
}
.timer__cont > div:nth-child(4){
    border-right: none !important;
}
.timer__cont--numbers{
    display: flex;
    justify-content: center;
}
.timer__cont--title{
    display: flex;
    justify-content: center;
}
.privacy__header--bg{
    height: 3.5em;
    background-color: #0c5192;
}

.about__third--cont{
    display: flex;
}
.about__third--cont > div {
    color: white;
    text-align: center;
    margin:auto;

}
.functionality__cont--img{
    width:100%;
    width: auto;
    max-height: 60px;
}
.button__first{
    width: 100%;
    /* max-width: 310px; */
    margin-top:2rem !important;
}
.about__third--cont > div > h2 {
    font-family: MaximaEF;
    font-weight: 400;
    margin:0;
    font-size: 1.5rem;
}
.about__third--cont > div > h3{
    margin-bottom: 1em;
}
.about__third--cont > div > div {
    width: 65%;
    margin:1.5em auto;
}
.about__important--orange{
    color: orange;
    font-weight: 700;
}
.aboutUs__cont--txt > div > a {
    margin:auto;
    border: solid 1px #0c5192;
}
.home__cont--change{
    width:100%;
}
.home__third--cont{
    display: flex;
    justify-content: space-between;
}
.home__third--cont > div {
    padding: 1em;
    justify-content: center;
    margin:auto;
}


.functionality__cont{
    border-bottom: 1px solid #CACFD4;
}
.home__third--cont > div > h1 {
    color: white;
    font-weight: 500;
    margin:auto 0.5em;
}

.home__third--cont > div > div > h3{
    color: white; 
}
.amniseFirst__cont{
    background: transparent;
}
.amniseFirst__cont--info{
    text-align: left;
    margin-top: 20px;
    color:white;
}
.amniseFirst__cont--title{
    font-weight: bold;
    color:white;
}
.amiseFirst__cont--subtitle{
    font-weight: bold;
    color:white;
    margin:0;
}
.amiseFirst__cont--subtitle--ul{
    color:#F9AF23;
    margin:0;
    font-weight: 400;
    font-family: OpenSans;
}
.amniseFirst__cont--info2{
    color:white;
    margin-top:1em;
    line-height: 1.5;
}
.contact__cont--thx{
    text-align: center;
    
}
.third_section > div > div >h3 {
    font-family: OpenSans !important;
}
.carousel-content{
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
}
.carousel-item{
    margin:auto 3vw;
}
.contact__cont--name{
    color:white;
    text-align: left;
}
.contact__cont--thx--description{
    font-size: 3vh !important;
    color:white;
    text-align: center;
}
.contact__info--cont > div > div{
    font-size: 14px;
}
.aboutUs__cont--description, .about__cont--description{
    font-size: 1em;
    color:black;
}
.Typewriter__cursor{
    display: none;
}
button {
    cursor: pointer;
}
.btn__change--cont{
    display: flex;
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    justify-content: space-between;
}
button:focus {outline:0;}
/* input:focus{outline:0;
} */
.btn__change{
    font-size: 1.1em;
    font-family: OpenSans;
    background: transparent;
    border: 1px solid #69afea;
    color: #69afea;
    height: 2.5rem;
    width:2.5rem;
}
.btn__change:hover{
    background: #69afea;
    color: rgba(0,0,0,0.3);
}
.aboutUs__cont--title{
    font-weight: 700;
    color: #0c5192;  
    text-align: left;
    margin: 0 0 25px 0;
    text-align: center;
}
.header__txt--blue{
    text-align: center;
    font-weight: 700;
    color: #0c5192;  
    margin: auto;
}
.description__txt--blue{
    color: #0c5192;  
    margin:1rem auto;
    text-align: center;
}
.aboutUs__cont, .carousel__cont{
    display: flex;
    background: white;
}

.ContactUs__cont{
    background: #3b2b92;
}
.icons__footer{
    display: flex;
    color:#ffffff;
    font-weight: 600;
    position: relative;
    margin: 0 0 0.5em;
}
.icons__footer > p {
    margin:0;
}

.date__cont > p {
    margin:0;
}
.contact__info--cont--date{
    font-size: 14px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;

}
.date__cont{
    min-width: 180px;
    max-width: 180px;
    margin: 0 40px 1rem;
    color: white;
}
.contact__info--icon{
    width:auto;
    height: 16px;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translate(-50%, -50%);
}
.contact__info--cont--img{
    margin-bottom: 2rem;
    margin-left: 40px;
}
.date__cont > img {
    margin: 0 0.5rem 0 0; 
    cursor: pointer;
    transition: 0.2s all;
}

.date__cont > img:hover {
    opacity: 0.8;
}

.section, .about_section, .home__section{
    position: relative;
    /* text-align: justify; */
    z-index: 3;
}
.about_section {
    display: flex;
}
#partnerstwo {
    margin-top:1em;
}

.aboutUs__cont--description, .about__cont--description{
    font-size: 16px;
}
.functionality__cont{
    width: 100%;
    margin: auto;
    background: white;
    display: flex;
    flex-wrap: wrap;
}
.functionality__cont--introduction {
    font-weight: 700;
    color: #0c5192;
}
.functionality__cont > h4 {
    text-align: center;
    font-weight: 700;
    color: #0c5192;
}
.functionality__cont--item{
    border-bottom:1px solid #CACFD4;

}
.home__cont--item{
    border-top: 1px solid #CACFD4;
    border-left: 1px solid #CACFD4;
}
.functionality__cont > h4{
    color:  #0c5192;
    text-align: center;
    justify-content: center;
}

.home__cont--description {
    text-align: center;
    font-size: 18px;
    margin: auto;
}
.offer__cont--description {
    text-align: center;
    margin: auto;
    color:white;
}
.functionality__cont--item--inside{
    padding: 3vw 7vw;
    text-align: center;
}
 .changeContent__cont--item, .home__cont--item {
    text-align: center;
}
.changeContent__cont--item{
    cursor: pointer;
    text-transform: uppercase;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}


.home__cont--item--inside{
    margin: auto;
}
.changeContent__cont--item--inside {
    display: flex;
    margin: auto;
    padding: 0 1em;
}

.btn__mobile--lang{
    position: absolute;
    top: 1.5em;

}

.show__up > div > p{
    opacity: 1;
    transition-delay: 2s;
}

.offer__cont--title {
    color: white;
    font-weight: 700;
    margin:auto;
}
.functionality__cont--withImg{
    background-image: url(../desktop/about-secound-im.jpg);

}


.functionality__cont--hand{
    position: absolute;
    width: 100%;
    top: -0.5em;
    left: -20%;
}
.bg__btn{
    background: white;
    margin:0.5rem 0;
    width:100%;
    /* max-width: 310px; */
}
.bg__btn--ui {
    background: white;
    margin:2em 0 1em;
    text-align: center;
}

.ui__cont{
    padding:2em 10vw;
    text-align:center;
}

.ui__cont--title{
    margin:0.5em 0;
    color:white;
    text-align: left;
}
.ui__const--description{
    color:white;
    margin:auto;
    margin-top:1em;
    font-size: 16px;
}
ul {
    text-align: left;
    list-style-type: square;
    color:white;
}

.contact__const--btn{
    border: none;
    font-size: 1.4em;
    margin: 10px auto 1rem;
    width:100%;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    color: #0c5192;
    background: white;
    transition: 0.3s;
    padding:13.5px;
    border: 1px solid #083C99;
    text-transform: uppercase;
    box-sizing: border-box;
}

.contact__const--btn:hover{
    transform: scale(1.05);
}
.functionality__cont--title{
    /* background-image: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);   
    -webkit-background-clip: text; */
    color: black;  
    font-weight: 400;
    margin:0;
}

.Ui__const--btn{
    text-transform: uppercase;
    width:100%;
    font-size: 1.5em;
    border:0;
    font-weight: 800;
    margin:1.5em 0;
    color:purple;
    padding:1em;
    background: white}
.ui__cont--change{
    padding:1em 0;
}


  .contact__cont-img{
      position: relative;
      bottom:0;
      width:100%;
  }
  .contact__cont--txt{
      text-align: center;
  }
  .contact__info--cont{
      margin:auto;
      text-align: left;
      border-bottom: 1px solid #4C5EB3;
  }

  .contact__info--cont > div > div > a{
      color:#ffffff;
      margin:0 0 0.5em 0;
      position: relative;
  }
  .contact__numer{
      font-size: 1.5em;
  }
  .contact__info--sm{
      display: flex;
  }

  .contact__info--sm > img {
      cursor: pointer;
      transition: 0.3s;
      width: 35px;
      height: 35px;
  }
  .contact__info--sm > img:hover {
    transform: scale(1.1);
}
  .carousel{
      margin-top:2em;
  }
  .carousel-item{
    cursor: pointer;
    height: auto;
    width: 18%;
    max-width: 300px;
    transition: 0.3s;
  }
  .carousel-item:hover{
    transform: scale(1.1);
  }


  /* Progress line */
  .progressVisualFull {
    display: flex;
    position: relative;
    background: #0c5192 !important;
    background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%) !important; 
    border-radius: 10px;
    /* change the value if you want different thickness */
    height: 2px;
  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    border-radius: 10px;
    transition: width 2s;
  }
  
  .progressLabel {
    /* Nothing here, move along */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  .contact__politycs{
      text-align: justify;
      color:#0c5192;
      font-size: 0.9em;
    }
    .contact__footer{
        color:#ffffff;
        text-align: left;
        padding: 30px 0;
    }
    
    .contact__footer > div > a {
        transform: scale(1);
        color:#ffffff;
        font-weight: bold;
    }
    .privacy__chapter--title{
        text-align: center;
        margin: 0;
        background: linear-gradient(135deg,rgba(62,54,153,1) 0%,rgba(83,112,192,1) 35%,rgba(105,175,234,1) 100%);
        padding: 2rem;
        color:white;
    }
    .privacy__chapter > ul, .cookie__chapter > ul{
        color:black;
        list-style: decimal;
    }
    .privacy__chapter > h3 {
        text-align: center;
        font-weight: 700;
    }
    .privacy__chapter > ul > li > ul, .cookie__chapter > ul > li > ul {
        color:black;
        list-style: lower-alpha;

    }

    
    .offer__img {
        background-image: url(../mobile-portrait/Oferta_mobile--l.jpg);
    }
    .hiper__img{
        background-image: url(../desktop/hiper.jpg);
    }
    .rpa__img{
        background-image: url(../desktop/rpa.jpg);
    }
    .mlai__img{
        background-image: url(../desktop/ml_ai.jpg);
    }
    .mobileApp__img{
        background-image: url(../desktop/apps.jpg);
    }
    .ibmps__img{
        background-image: url(../desktop/ibpm.jpg);
    }

    

@media screen and (min-aspect-ratio: 13/9) and (max-width: 812px) {
    .aboutUs__cont--txt, .carousel--carousel{
        width:90%;
        margin:2em auto;
    }
    .contact__cont--img{
        width:100%;
        height: 40vw;
    }
    .contact__info--sm > img {
        margin:1em 1em 1.5em 0;
    }
    
    
    .functionality__cont--withImg--img{
        height: auto;
        width:100%;
    }
    .functionality__cont--hand{
        max-width:450px;
        left: -10% !important;
    }
 

    /* .carousel-content{
        justify-content: space-evenly;
        flex-wrap: wrap;
        display: flex;
    } */
    .carousel-item{
        margin:auto 0.5em;
        
    }
    
    .progressVisualFull{
        height: 3px;
        justify-content: left;
        width:50%;
        margin-top: 2vw !important;
    }
    .logo {
        display: none;
    }
  
    .home__third--img {
        background-image: url(../mobile-portrait/oszczednosci_mobile.jpg);
    }
    .amniseFirst__cont{
        padding:2vw 3vw !important
    }
    .functionality__cont--item:nth-child(1){
        color:white;
        background: rgb(62,54,153);
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
    }
    .functionality__cont--item:nth-child(3){
        color:white;
        background: rgb(62,54,153);
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
    }
    .functionality__cont--item:nth-child(1) > .functionality__cont--item--inside > div > .functionality__cont--title{
        color:white !important;
    }
    .functionality__cont--item:nth-child(1) > .functionality__cont--item--inside > div >  .functionality__cont--title{
        color:white !important;
    }

}

@media screen and (max-aspect-ratio: 13/9) and (max-width:767px) {
  

    .functionality__cont--withImg--img{
        width:100%;
        height: auto;
    }
    .contact__cont--img{
        height: 60vw;
        background-image: url(../mobile-landscape/hands.jpg);
    }

    .contact__info--sm > img {
        margin:1em 1em 1.5em 0;
    }

    .functionality__cont--item--inside{
        display: flex;
    }
    
    .functionality__cont--item--inside > div{
        margin: auto;
    }
    
    .carousel__cont{
        min-height: unset;
        
    }
    .carousel--carousel{
        width:90%;
        margin:2em auto;
        text-align: center;
    }
    .carousel-item{
        margin: auto;
        padding: 1em;
    }
    
    
    .functionality__cont--item:nth-child(1){
        color:white;
        background: rgb(62,54,153);
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
    }
    .functionality__cont--item:nth-child(3){
        color:white;
        background: rgb(62,54,153);
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
    }
    .functionality__cont--item:nth-child(1) > .functionality__cont--item--inside > div > .functionality__cont--title{
        color:white !important;
    }
    .AmiseFirst__cont--subtitle--ul{
        font-size: 3vh;
    }
    .functionality__cont--item:nth-child(3) > .functionality__cont--item--inside > div > .functionality__cont--title{
        color:white !important;
    }

    
}

@media screen and (orientation: landscape)  and (min-width:813px) {

    .about_section, .home__section{
        min-height: 380px;
    }
    .third_section{
        min-height: 380px;
        padding: 50px 4vw;
    }
    .third_section > div > div {
        /* margin: auto; */
    }


    
}

@media screen and (max-aspect-ratio: 13/9) and (min-width:768px)
{
    .about__cont--change{
        display: flex;
        }
        .aboutUs__cont--txt{
            order:2;
        }
        
        .aboutUs__cont{
            min-height: 0 !important;
        }
        .functionality__cont--withImg--img{
            width:100%;
            height: auto;
            margin:auto;
        }
       
        .progressVisualFull{
            height: 3px;
            justify-content: left;
        }
        .functionality__cont--change{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 2em;
        }
        
        .contact__cont--img{
            background-image: url(../mobile-landscape/hands.jpg);
            height: 50vw;
        }
        .functionality__cont--item:nth-child(1){
            border:1px solid #CACFD4;
        }
        .functionality__cont--item:nth-child(2){
            border-top: 1px solid #CACFD4;
            border-right: 1px solid #CACFD4;
            border-bottom:1px solid #CACFD4;
            color:white;
            background: rgb(62,54,153);
            background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
        }
        
        .functionality__cont--item:nth-child(3){
            border-bottom: 1px solid #CACFD4;
            border-left: 1px solid #CACFD4;
            color:white;
            background: rgb(62,54,153);
            background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%);
        }
        .functionality__cont--item:nth-child(2) > .functionality__cont--item--inside > div >.functionality__cont--title{
            color:white !important;
        }
        .functionality__cont--item:nth-child(3) > .functionality__cont--item--inside > div > .functionality__cont--title{
            color:white !important;
        }
        .functionality__cont--item:nth-child(4){
            border-left: 1px solid #CACFD4;
            border-right:1px solid #CACFD4;
            border-bottom:1px solid #CACFD4;
        }

        .functionality__cont--item{
            width:45%;
        }
        .functionality__cont{
            min-height: 0;
        }
        .carousel--carousel{
            margin:1em 8vw;
            width:100%;
        }
        
        .Ui__const--btn{
            width:60%
        }
         .carousel--carousel{
            width:90%;
            margin:2em auto;
        }
        .bg__btn--ui{
            width:60%;
        }


       
   
    
}

@media screen and (min-aspect-ratio: 13/9) {

    
    
    
}
@media screen and (min-aspect-ratio: 13/9) and ( min-width: 813px) {
    .about__cont--change{
        display: flex;
        }


   
    
    

    

    .functionality__cont--withImg--img{
        width:100%;
        height: auto;
        margin:auto;
    }
    
    .aboutUs__cont, .functionality__cont{
        min-height: 0 !important;
        height: unset !important;
    }
    .functionality__cont--change{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin:0 15vw;
        padding-bottom: 2em;
    }
    .functionality__cont--item:nth-child(1){
        border:1px solid #f2f2f2;
    }
    .functionality__cont--item:nth-child(2){
        border-top: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        border-bottom:1px solid #f2f2f2;
        color:white !important;
        background: #0c5192;
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 25%, rgba(105,175,234,1) 80%);
    }
    .functionality__cont--item:nth-child(2) > .functionality__cont--item--inside > div >.functionality__cont--title{
        color:white !important;
    }
    .functionality__cont--item:nth-child(3) > .functionality__cont--item--inside > div > .functionality__cont--title{
        color:white !important;
    }
    .functionality__cont--item:nth-child(3){
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        color:white !important;
        background: #0c5192;
        background: linear-gradient(90deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 25%, rgba(105,175,234,1) 100%);

    }
    .functionality__cont--item:nth-child(4){
        border-left: 1px solid #f2f2f2;
        border-right:1px solid #f2f2f2;
        border-bottom:1px solid #f2f2f2;
    }
    .functionality__cont--item{
        width:calc(50% - 2px);
    }
    .contact__cont--img{
        width:60%;
        order:2;
    }

    

    .about__important--orange{
        font-size: 9vh;
    }
    .bg__btn--ui{
        width:100%;
        max-width:310px;
    }
    .carousel--carousel{
        margin:2em 8vw;
        width:100%;
    }
    /* .carousel-content{
        justify-content: space-evenly;
        flex-wrap: wrap;
        display: flex;
    } */
   
    /* .amniseFirst__cont--info{
        height: 10em !important;
    } */
    .progressVisualFull{
        height: 3px;
        justify-content: left;
    }


}

@media screen and (min-aspect-ratio: 13/9) and ( min-width: 1600px) {

    .about__third--cont > div > h2, h3 {
        font-size: 2vw;

    }
    .home__cont--description{
        font-size: 14px;
    }
}



.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container p {
    font-size: 3rem;
    padding: 0.5rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-align: center;
    overflow: hidden;
  }
  .container p span.typed-text {
    font-weight: normal;
    color: #dd7732;
  }
  .container p span.cursor {
    display: inline-block;
    background-color: #ccc;
    margin-left: 0.1rem;
    width: 3px;
    animation: blink 1s infinite;
  }
  .container p span.cursor.typing {
    animation: none;
  }
  @keyframes blink {
    0%  { background-color: #ccc; }
    49% { background-color: #ccc; }
    50% { background-color: transparent; }
    99% { background-color: transparent; }
    100%  { background-color: #ccc; }
  }

  @media screen and (max-width: 360px) {
    .carousel-item{
        max-width:260px !important
    }
  }
  @media screen and (max-aspect-ratio:13/9) and (max-width: 457px) {

      .contact__cont--img{
        background-image: url(../mobile-portrait/hands-mobile-portrait.jpg);
    }
    .home__third--img{
        background-image: url(../mobile-portrait/oszczednosci_mobile.jpg);
    }

    .about_img {
        background-image: url(../mobile-portrait/layer-8.jpg);
        width: 100%;
        height: auto;
        display: flex;
    }
    .about__cont--description{
        margin: 1em;
    }

    .home__third--img > div {
        text-align: center;
        display: block !important;
    }
    .functionality__cont--title{
        font-size: 24px;
        margin:0;
    }
    .home__cont--description{
        font-size: 14px;
    }
    
    .functionality__cont > h4{
        margin: 2em;
    }


  }
  
  
  @media screen and (max-aspect-ratio:13/9) and (min-width: 458px) and (max-width: 1024px){

    .about_img {
        background-image: url(../desktop/About_bg.jpg);
        width: 100%;
        height: auto;
    }
    .home__third--img {
        background-image: url(../mobile-portrait/oszczednosci_mobile.jpg);
    }

    .functionality__cont > h4{
        margin: 2em 4em;
    }
    
  }
  @media screen and (min-aspect-ratio:13/9) and (min-width: 458px)  and (max-width: 812px) {

    .home__third--img {
        background-image: url(../mobile-portrait/oszczednosci_mobile.jpg);
    }


    .home__third--cont > div {
        /* display: flex; */
    }
    .home__third--cont > div > h1 {
        font-size: 10vw !important;
    }
    .home__third--cont > div > div > h3{
        font-size: 7vh !important;
    }


    .home__cont--description{
        margin: 0 0.5em;
    }

    .about_section, .home__section{
        min-height: 262px;
    }
  }

  @media screen and (min-aspect-ratio:13/9) and (min-width: 813px) and (max-width:1600px){
    .about_img {
        background-image: url(../desktop/About_bg.jpg);
    }
    .home__third--img {
        background-image: url(../desktop/oszczednosci.jpg);
    }
  }
  @media screen and (max-aspect-ratio:13/9) and (min-width: 813px) and (max-width:1600px){
    .about_img {
        background-image: url(../desktop/About_bg.jpg);
    }
    .home__third--img {
        background-image: url(../desktop/oszczednosci.jpg);
    }
}
  @media screen and (min-aspect-ratio:13/9) and (min-width: 1601px){
    .about_img {
        background-image: url(../desktop/about__2500px.jpg);
    }
    .home__third--img {
        background-image: url(../desktop/oszczednosci_2500px.jpg);
    }

  }
  @media screen and (max-width:812px) {
      
      .about__first{
          margin:auto 0;
          width:50%;
      }
      
      .button__first{
          width:70%;
          
      }


    
    
    .privacy__chapter, .cookie__chapter {
        padding: 10vw 5vw 0;
    }
    .privacy__chapter > ul{
        font-size: 4vw;
        padding:5vw;
    }
    .privacy__chapter > h3 {
        text-align: center;
        font-size: 6vw;
    }
    .privacy__chapter > ul > li > ul, .cookie__chapter > ul > li > ul {
        padding: 2vw 5vw;
        
    }
    
    .progressVisualFull{
        height: 3px;
        justify-content: left;
        margin-top: 3vw;
        width:100%;
    }
    

    

  }

  @media screen and (min-width: 813px)  {
      .navbar > ul > li > a {
          margin: 1rem 0 !important;
      } 
      .privacy__chapter--title{
          font-size: 3rem;
          padding: 2rem;
      }
      .privacy__chapter, .cookie__chapter{
          padding: 3vw 10vw 2vw;
      }
      
      
    .bg__btn, .bg__btn--ui{
        max-width: 310px;
    }
    .contact__cont--txt{
        padding-top:2em ;
    }
    .progressVisualFull{
        margin-top:2rem;
        width:70%;
        height: 4px;
    }
    .about__first > div > div {
        width:40% !important;
    }
    
    .amniseFirst__cont--info2{
        margin-top:1rem;
    }

    
    
    
    .contact__politycs{
        width:75%;
    }
  }
   
  @media screen and (max-width:1150px) {

    .logo__footer{
        width:100%;
        max-width: 110px;
        text-align: right;
        justify-content: right;
        display: flex;
    }
    .offer__btn{
        margin: .5rem auto !important;
    }
    
}
@media screen and (max-width: 991px) {
    .amniseFirst__cont, .about__first{
        padding:5%;
    }
    .logo{
        width:100%;
        max-width: 60px;
    }
    .amniseFirst__cont--title{
        font-size: 44px;
    }
    .amiseFirst__cont--subtitle{
        font-size: 20px;
    }
    .amiseFirst__cont--subtitle--ul{
        font-size: 20px;
    }
    .aboutUs__cont--title{
        font-size: 36px;
    }
    .webinar__info {
        margin-top:2em;
    }
    .webinar__info > h3 {
        font-size: 22px;
        
    }
    .ui__cont--title{
        font-size: 22px;
        text-align: center;
    }
    .aboutUs__cont--txt{
        padding:5%;
    }
    .timer__cont{
    /* padding-top: 0.5em; */
    margin: 1rem auto 1rem 0;
    }
    .timer__cont{
        width: 100;
        max-width: 235px;
    }
    .timer__cont--numbers{
        font-size: 36px;
    }
    .timer__cont--title{
        font-size: 12px ;
    }
    .functionality__cont--introduction{
        font-size: 16px;
        margin:5%;
        text-align: justify;
    }
    .functionality__cont--interview{
        padding:5%;
        width: 100%;
    }
    .functionality__cont--interview >h2 {
        text-align: center !important;
    }
    .changeContent__cont--item--inside{
        height: 300px;
    }
    .offer__cont--description{
        font-size: 13px;
    }
    .ui__cont--logo{
        width:100%;
        max-width: 330px;
        height: auto;
    }
    .description__txt--blue{
        font-size: 16px;
    }
    .home__third--cont > div{
        text-align: center;
    }
    .home__third--cont>div>h1{
        font-size: 5rem;
    }
    .header__txt--blue{
        font-size: 36px;
    }
    .input__cont {
        text-align: center;
    }
    
    .newsLetter__section{
        padding:2rem;
        
    }
    .Contact__cont--txt{
        width:100%;
        max-width: 600px;
        margin:auto;
    }
    .contact__info--sm > img {
        margin:1em 1em 1em 0;
    }
    .home__cont--item{
        width:50%;
        padding: 3em 0.5em;
    }
    .changeContent__cont--item{
        width:100%;
    }
    .input__cont > div {
        margin:1em 0;
    }
    .privacy__header--bg{
        height: 0 !important;
    }
    .unsubscribe__cont > div {
        padding:4em 2em;
    }
    .home__cont--border-top{
      display: flex;
    }
    .functionality__cont--item--inside{
        display: flex;
    }
    .functionality__cont--item--inside > div{
        margin: auto;
    }
    .about__first {
        min-height: 262px;
    }
    .about__cont--txt{
        padding:2em 5%;
    }
    .contact__cont--txt{
        padding:2rem 2rem 5rem;
    }
    .ui__const--description{
        text-align: left;
    }

}
@media screen and (min-width: 992px) {
    .amniseFirst__cont, .about__first{
        padding:5vw 10vw;
        height: 100%;
    }
    .logo{
        width: 100%;
        max-width: 80px;
    }
    .amniseFirst__cont--title{
        font-size: 56px;
    } 
    .amiseFirst__cont--subtitle{
        font-size: 28px;
    }
    .amiseFirst__cont--subtitle--ul{
    font-size: 25px;
    }
    .aboutUs__cont--title{
        font-size: 25px;
        text-transform: uppercase;
    }
    .newsLetter__section{
        padding:2rem 5vw;
    }
    .home__cont--border-top {
        display: flex;
        width: 50%;
    }
    .offer__cont--description{
        font-size: 14px;
    }
    .changeContent__cont--item{
        width:33%;
        flex-grow: 1;
        height: 360px;
        display: flex;
        margin:auto;
    }
    
    .aboutUs__cont--txt > div {
        display: flex;
        justify-content: space-between;
    }
    .aboutUs__cont--description{
        width: 45%;
    }
    .webinar__info > h3{
        font-size: 22px;
    }
    .ui__cont--title{
        font-size: 28px;
    }
    .timer__cont{
        width:100%;
        max-width: 310px;
        padding-top:0.5em;
    }
    .timer__cont--numbers{
        font-size: 40px;
    }
    .timer__cont--title{
        font-size: 16px;
    }
    .functionality__cont--introduction{
        font-size: 22px;
        margin:2em 10vw;
        text-align: center;
    }
    .functionality__cont--interview{
        padding:2em;
        width: 100%;
    }
    .functionality__cont--interview >h2 {
        text-align: center !important;
    }
    .description__txt--blue{
        font-size: 15px;
        max-width: 450px;
    }
    .home__third--cont > div > h1{
        font-size: 15vh !important;
    }
    .home__cont--item{
        width:50%;
        padding: 4em 1em;
    }
    .home__cont--change{
        display: flex;
    }
    .home__third--cont > div {
        display: flex;
    }
    .about__cont--txt{
        margin:auto;
        order:2;
        width:60%;
        padding:2em 10vw 2em 4em;
        text-align:justify;
    }
    .unsubscribe__cont{
        height: calc(100vh - 256px);
    }
    .aboutUs__cont--txt{
        margin: 2em 10vw;
    }
    .ui__cont{
        display: flex;
    }
    .ui__cont--logo{
        max-width: 330px;
        height: auto;
        margin:auto 2em auto 0;
    }
    .ui__cont--change{
        text-align: left;
    }
    .contact__info--cont{
        display: flex;
        justify-content: space-between;
    }
    .contact__footer{
        display: flex;
        justify-content: space-between;      
    }
    .logo__footer {
        margin: 0;
        width:100%;
        max-width: 75px;
        transform:scale(1.1);
      }
      .contact__info--sm > img {
        margin:0 1em 1em 0;
    }
    .contact__footer > div > a {
        margin-left: 2rem;
    }
    .contact__footer > div {
        display: flex;
        justify-content: space-between;
        order:2;
    }
    .header__txt--blue{
        text-align: center;
    }
    .description__txt--blue{
        margin:2rem auto;
        padding: 0 5%;
    }
    .header__txt--blue{
        font-size: 48px;
    }
    .input__cont{
        display: flex;
        justify-content: space-between;
        margin:auto;
    }
    .input__cont > div {
        max-width: 290px;
        margin:1rem 1rem 1rem 0;
        width:100%;
    }.input__cont > label {
        max-width: 350px;
    }
    .contact__const--btn{
        max-width: 310px;
    }
    .input__cont > button {
        max-width: 200px;
    }
    .Contact__cont--txt{
        width: 100%;
        max-width: 820px;
        margin: auto;   
    }
    
    .unsubscribe__cont > div {
        padding:4em;
    }
    .functionality__cont--withImg{
        display: flex;
        width:40%;

    }
    .contact__cont--txt {
        padding-top: 2rem;
        /* margin: auto 10vw; */
    }
    
}




.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.active__terms{
    background: #0c5192 !important;
    color: #ffffff !important;
}

.unsubscribe__cont{
    text-align: center;
}



.aboutUs__cont--txt{
    order:2;
    text-align:justify;
}
@media screen and (min-width: 1365px) {
    .offer__cont--description {
        font-size: 16px !important;
    }
}
@media screen and (min-width: 576px) {
    .bg_AmniseFirst{
        background-image: url(../firstbBG-desktop.jpg);
    }
    .contact__info--cont{
        display: flex;
    }
    .date__cont {
        margin: 0 0 1rem auto !important;
    }
    .contact__info--cont--img{
        margin-right: 50px !important;
        margin-left: 0;
    }
    .contact__footer > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 575px){
    .bg_AmniseFirst {
        background-image: url(../mobileImage.jpg);
    }

}

#header li a {
    color: #083C99;
}

#header div a img {
    width: 140px;
}
.company-section-container {
    font-size: 22px;
    display: flex;
    flex-direction: column;
    color: white;
    width: 25%;
}

.company-section-container h4 {
    color: #FFAB0A;
    margin-bottom: 5px;
}

.cost-reduction-block {
    display: flex;
    margin-top: 25px;
    align-items: center;
}

.cost-reduction-block p {
    margin: 0;
    font-size: 20px;
}

.cost-reduction-block .info-square {
    border: 1px solid #83D0FF;
    color: #FFAB0A;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 75px;
    height: 75px;
    margin-right: 20px;
    min-width: 75px;
}

.third_section{
    padding: 50px 4vw;
}

.functionality__cont .article-box {
    border-top: 5px solid #536BEC;
    width: 30%;
    box-shadow: -3px 2px 12px 3px #b8b8b857;
    overflow: hidden;
}

.functionality__cont .article-box .more-btn-container {
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    padding: 0px 30px 20px;
    /* box-shadow: 0px -20px 45px 11px white; */
    /* position: relative; */
    /* z-index: 100; */
}

.functionality__cont .article-title {
    color: #083C99;
    border-bottom: 1px solid #83D0FF;
    margin: 0;
    padding: 25px 30px;
    font-size: 22px;
    font-weight: bold;
}

.functionality__cont .article-content {
    padding: 15px 30px;
    max-height: 130px;
    overflow: hidden;
}

.simple-btn-blue {
    /* font-size: 1.4em; */
    font-weight: bold;
    text-align: center;
    justify-content: center;
    color: #0c5192;
    background: white;
    transition: 0.3s;
    padding: 13.5px;
    border: 1px solid #083C99;
    text-transform: uppercase;
    font-size: 15px;
}

.functionality__cont {
    /* padding: 55px 70px; */
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.about__cont--change{
    /* padding-top: 70px; */
}

.home-offer__cont {
    background: linear-gradient(180deg, #1B659F 5.86%, #3F379A 89.43%);
    padding: 60px 15vw;
    color: white;
    position: relative;
}

.home-offer__cont .offer-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
}

.home-offer__title {
    text-transform: uppercase;
    border-bottom: 5px solid #83D0FF;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding: 8px;
}

.home-offer__tags {
    color: #83D0FF;
    width: 90%;
    text-align: center;
    margin: 5vw auto;
}

.home-offer__box-cont {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    text-transform: uppercase;
}

.home-offer__box-cont .offer-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-align: center;
    font-family: 'MaximaEF';
    transition: 0.2s all;
    cursor: pointer;
}

.home-offer__box-cont .offer-box:hover {
    transform: scale(1.05);
}

.home-offer__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.home-partners__title, .rpa-ask-for-contact__title, .mobile-ask-for-contact__title {
    text-align: center;
    color: white;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.home-partners__cont, .rpa-ask-for-contact__cont, .ask-for-contact__cont {
    padding: 40px 50px 80px;
    background-repeat: no-repeat;
    background-size: cover;
}

.ask-for-contact__cont, .rpa-ask-for-contact__cont {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partners-logo__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 80px; */
    padding: 0 80px;
}

.partners-logo__wrapper img.ui-partner {
    width: 40%;
}

.partners-logo__wrapper img.abbyy {
    width: 21%;
}

.partners-logo__wrapper img.saio {
    width: 25%;
    padding-right: 50px;
}

.home-contact-btn {
    margin: 1rem 1rem 1rem 0;
}

.amiseFirst__cont--subtitle{
    position: relative;
    top: -8px;
}

.progressVisualFull{
    margin-top: 10px;
    width: 70%;
    height: 2px;
}

.carousel__cont .carousel-item {
    width: 18%;
    margin: 0;
}

.carousel__cont .d-flex{
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.carousel__cont .logos-second-row {
    justify-content: center;
}

.carousel__cont .logos-second-row .carousel-item {
    width: 28%;
    margin: 0 20px;
}

.contact__info--cont {
    padding: 20px 8vw;
}

.contact__footer {
    padding: 30px 8vw;
}

.rpa_cont1{
    background-image: url(../../assets/bgImg/map_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 65px);
}

/* Customers */

.customers_cont1 {
  background-image: url(../../assets/bgImg/customers_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 65px);
}

.customers_cont2 .choose-branch-container {
  min-width: 320px;
}

.customers_cont2 .tabs-container {
  display: flex;
  justify-content: space-between;
}

.customers_cont2 .tab-content-container {
  padding: 0 50px;
  text-align: justify;
}

.customers_cont2 .tab-content-container p {
  font-size: 18px;
}

/* .customers_cont2 .txt-sm {
  font-size: 14px;
} */

.page-title-content__wrapper {
    padding: 5vw 10vw;
    background: rgba(6, 18, 39, 0.409);
    height: 100%;
    box-sizing: border-box;
}

.page-title-content__wrapper .title-container {
    text-align: left;
    margin-top: 13vh;
    color: white;
}

.page-title-content__wrapper .title-container .title {
    font-size: 3vmax;
}

.page-title-content__wrapper .title-container .subtitle {
    font-size: 1.5vmax;
    font-weight: 600;
    margin-top: 8px;
}

.page-title-content__wrapper-mid {
  padding: 10vw;
  background: #06122759;
  box-sizing: border-box;
  height: 100%;
  color: white;
}

.page-title-content__wrapper-mid .title-container .title {
  font-size: 3vmax;
}

.page-title-content__wrapper-mid .title-container .subtitle {
  font-size: 1.5vmax;
  font-weight: 600;
  margin-top: 8px;
}

/* HELPER GLOBAL STYLES */

.blue-bold__subtitle {
    font-size: 28px;
    color: #083C99;
    text-transform: uppercase;
    margin: 0;
}

.txt-sm {
    font-size: 16px;
}

.txt-md {
    font-size: 24px;
}

.txt-blue {
    color: #083C99;
}

.btn-orange {
    font-size: 20px;
    color: white;
    background: #FFAB0A;
    padding: 15px 35px;
    text-align: center;
    border: none;
    font-family: 'MaximaEF';
    text-transform: uppercase;
    margin: 0 auto;
    display: block;
    position: relative;
    transition: 0.2s all;
}

.btn-orange:hover {
    background: white;
    color: #083C99;
}

.btn-orange:hover::after {
    width: 100%;
}

.sm-blue-line {
    background: #83D0FF;
    width: 125px;
    height: 3px;
    margin: 20px 0;
}

.sm-blue-line.line-disabled {
  background: #c4c4c4;
  width: 65px;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

.d-flex-sp-btwn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.d-flex-sp-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.content__wrapper {
    padding: 3vw 10vw;
}

.w-25 {
    width: 25%;
}

.w-32 {
    width: 32%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.mt-5 {
    margin-top: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mr-25 {
    margin-right: 25px;
}

.ml-25 {
    margin-left: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.shdw-sm {
    box-shadow: -3px 2px 12px 1px #b8b8b857;
    transition: 0.2s all;
}

.shdw-sm:hover {
    box-shadow: -3px 2px 12px 3px #b8b8b857;
}

.el-center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.info-box {
    border-top: 5px solid #536BEC;
    width: 31%;
    padding: 30px 40px 20px 40px;
    background: white;
    transition: 0.2s all;
}

.info-box:hover {
    background: #536BEC;
    color: white;
    transform: scale(1.05);
}

.txt-bold {
    font-family: MaximaEF;
}

.cta-btn {
  cursor: pointer;
  transition: 0.2s all;
}

.cta-btn:hover {
  opacity: 0.9;
}

.txt-disabled {
  color: #C4C4C4;
}

/* HELPER GLOBAL STYLES END */

.info-box p {
    transition: 0.2s all;
}

.info-box:hover p {
    color: white;
}

.info-box img {
    transition: 0.2s all;
}

.info-box:hover img {
    filter: brightness(0) invert(1);
}

.rpa_cont2 .txt-sm {
    text-align: justify;
}

.rpa_cont2 .txt-container {
    width: 48%;
}

.info-box-sp-btwn {
    border-top: 5px solid #536BEC;
    width: 31%;
    padding: 30px 40px 20px 40px;
    text-align: center;
}

.info-box-sp-btwn img {
    height: 27%;
}

.rpa_cont4 .info-box {
    width: 29%;
    padding: 30px 40px 20px 40px;
    margin: 20px 2%;
}

.rpa_cont4 .info-box p {
    min-height: 40px;
}

.rpa_cont4 .info-box img {
    margin-left: auto;
    display: block;
    width: 45%;
}

.rpa-step-box {
    border: 1px solid #e7e7e7;
    width: 25%;
    height: 24vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 30px;
}

.rpa-step-box .step-title {
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 0;
}

.rpa-step-box .step-info-text {
    font-size: 18px;
}

.rpa-ask-for-contact__title {
    margin-bottom: 25px;
}

.rpa_cont7 .impl-step-title {
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 0;
    min-height: 55px;
}

.rpa_cont7 .impl-step-info-text {
    font-size: 16px;
}

.impl-step-box .img-cont {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background: #083C99;
}

.impl-step-box {
    border-top: 5px solid #083C99;
    width: 19%;
    position: relative;
    padding: 50px 20px 30px;
    text-align: center;
}

.impl-container {
    margin-top: 85px;
}

.rpa_cont7 .sub-title {
    font-size: 20px;
    font-family: 'OpenSans';
    font-weight: 700;
    margin: 40px 0 110px;
}

.impl-step-box .img-cont {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background: #083C99;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.impl-step-box .img-cont img {
    max-width: 70%;
}

.mobile_cont1{
    background-image: url(../../assets/bgImg/mobile1_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 65px);
}

.mobile_cont2 .info-box {
    width: 29%;
    padding: 30px 40px 20px 40px;
    margin: 20px 2%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 21vw;
}

.mobile_cont2 .title-box {
    width: 29%;
    margin: 20px 2%;
}

.mobile_cont2 .title-box .txt-sm {
    font-size: 12px;
    text-align: justify;
    margin: 0;
}

.mobile_cont2 .info-box img {
    width: 30%;
}

.mobile_cont2 .info-box .txt-bold {
    font-family: OpenSansBold;
}

.mobile_cont2 .info-box img {
    width: 30%;
}

.mobile_cont3 .info-box {
    width: 29%;
    padding: 30px 40px 20px 40px;
    margin: 20px 2%;
}

.mobile_cont3 .info-box p {
    min-height: 40px;
}

.mobile_cont3 .info-box img {
    margin-left: auto;
    display: block;
    width: 45%;
}

.mobile_cont3 .info-box p {
    font-size: 18px;
}

.mobile_cont4 {
    color: white;
}

.mobile_cont4 .title-box .txt-sm {
    font-size: 14px;
}

.mobile_cont4 .question-list__container .bold-light-blue {
    color: #83D0FF;
    font-family: OpenSansBold;
}

.mobile_cont4 .question-list__container ul {
    list-style-type: square;
    line-height: 1.9;
}

.mobile_cont4 .container-title {
    font-size: 28px;
    text-transform: uppercase;
    margin: 0;
}

.title-box, .question-list__container {
    width: 48%;
}

.solution-steps__cont .solution-step .img-container {
    border: 1px solid #83D0FF;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-steps__cont .solution-step {
    width: 120px;
}

.solution-steps__cont .solution-step .img-container img {
    width: 50%;
}

.solution-steps__cont .solution-step p {
    color: #83D0FF;
    font-size: 14px;
    text-align: center;
}

.solution-steps__cont .vector-container img {
    margin-top: 60px;
    transform: translateY(-50%);
    width: 15px;
}

.mobile_cont5 {
    padding: 50px 10px;
}

.mobile_cont5 .slider-container {
    padding: 20px 110px;
    position: relative;
}

.mobile_cont5 .slider-container .slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
}

.mobile_cont5 .slider-container .slider-arrow.slider-prev-arrow {
    left: 40px;
}

.mobile_cont5 .slider-container .slider-arrow.slider-next-arrow {
    right: 40px;
}

.slider-container .slider-arrow img {
    width: 100%;
}

.slider-container {
    height: 260px;
}

.slider-container .slider-img-wrapper img {
    width: 110px;
    height: 85px;
}

.slick-track {
    display: flex;
    align-items: center;
}

.slider-img-wrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slider-img-wrapper, .slick-slide, .slick-slide > div, .slick-track, .slick-list, .slick-slider {
    height: 100%;
}

.slider-container .slider-next-arrow img {
    transform: rotate(180deg);
}

.slider-arrow {
    cursor: pointer;
    transition: 0.2s all;
}

.slider-arrow:hover {
    opacity: 0.8;
}

.carousel-item.evp-logo {
    width: 12%;
}
.notFoundPage {
    text-align: center;
    margin: 30px 0px;
}
.notFoundPage__btn {
    text-align: center;
    color: #FFF;
    background: #3b2b92;
    display: block;
    max-width: 250px;
    margin: 0px auto;
    padding: 10px 0px;
    margin-top: 55px;
}

.attachment-container {
    display: flex;
    justify-content: center;
}

.attachment-btn {
    font-size: 14px;
}

.opinions-span {
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .company-section-container {
        width: 100%;
    }

    .home__third--cont > div {
        text-align: left;
    }

    .functionality__cont .article-box {
        width: 100%;
        margin-bottom: 25px;
    }

    .home-offer__box-cont {
        flex-wrap: wrap;
    }

    .home-offer__box-cont .offer-box {
        width: 100%;
    }

    .carousel__cont .carousel-item, .carousel-item.evp-logo, .carousel__cont .logos-second-row .carousel-item {
        width: 60%;
        margin: 0 auto;
    }

    .partners-logo__wrapper {
        flex-wrap: wrap;
    }

    .partners-logo__wrapper img {
        width: 70% !important;
        margin: 0 auto;
    }

    .partners-logo__wrapper img.abbyy {
        margin: 25px auto 5px;
    }

    .functionality__cont {
        padding: 55px 30px;
    }

    .page-title-content__wrapper .title-container .title, .page-title-content__wrapper-mid .title-container .title {
        font-size: 32px;
    }

    .page-title-content__wrapper .title-container .subtitle, .page-title-content__wrapper-mid .title-container .subtitle {
        font-size: 20px;
    }

    .rpa_cont2 .txt-container, .automation_cont > div {
        width: 100%;
        margin-bottom: 20px;
    }

    .automation_cont > div {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .info-box-sp-btwn {
        width: 100%;
        margin-bottom: 20px;
    }

    .rpa_cont4 .info-box {
        width: 100%;
    }

    .rpa-step-box {
        width: 100%;
        height: 300px;
    }

    .impl-step-box {
        width: 100%;
        margin-bottom: 70px;
    }

    .contact__info--cont {
        padding: 20px 0;
    }

    .mobile_cont2 .title-box, .mobile_cont2 .info-box, .mobile_cont3 .info-box, .title-box, .question-list__container {
        width: 100%;
    }

    .vector-container {
        display: none;
    }

    .solution-steps__cont .solution-step {
        width: 45%;
    }

    .partners-logo__wrapper {
        padding: 0;
    }
    
    .partners-logo__wrapper img.saio {
        padding-right: 0;
        margin-top: 25px;
    }

    .customers_cont2 .tabs-container {
      flex-wrap: wrap;
    }

    .customers_cont2 .tab-content-container {
      padding: 0;
    }

    /* .customers_cont1 {
      height: calc(100vh - 65px);
    } */
}

.d-flex-sp-btwn > div {
    width: 48%;
}

.d-flex-sp-btwn > div > p {
    width: 100%;
}

.automation_cont .txt-container {
    width: 100%;
}
.automation_cont .txt-sm {
    text-align: justify;
}

.dropdown {
    cursor: pointer;
}

.dropdown-content {
    position: relative;
    opacity: .9;
}

.dropdown-item.inactive {
    opacity: 0;
    cursor: default;
}

.dropdown-item {
    transition: .2s all ease;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-width: 150px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background: white;
}

.automation-link.inactive {
    cursor: default;
    pointer-events: none;
}
.automation-link.active {
    cursor: pointer;
}

.dropdown-item.active {
    opacity: 1;
    cursor: pointer;
}

.dropdown-item.active:nth-child(1), .dropdown-item.active:nth-child(2), .dropdown-item.active:nth-child(3) {
    transition: all .05s slidein;
}

.dropdown-item.active:nth-child(1) {
    top: 7px;
    transition-delay: 0s;
}

.dropdown-item.active:nth-child(2) {
    top: 59px;
    transition-delay: .1s;
}

.dropdown-item.active:nth-child(3) {
    top: 111px;
    transition-delay: .2s;
}

.dropdown-item.inactive:nth-child(1) {
    top: 7px;
    transition-delay: .2s;
}

.dropdown-item.inactive:nth-child(2) {
    top: 59px;
    transition-delay: .1s;
}

.dropdown-item.inactive:nth-child(3) {
    top: 111px;
    transition-delay: 0s;
}

.contact-link {
    font-size: 1.5em;
    text-transform: uppercase;
    margin: 0.5em;
    font-weight: bold;
    -webkit-letter-spacing: 0.5rem;
    -moz-letter-spacing: 0.5rem;
    -ms-letter-spacing: 0.5rem;
    letter-spacing: 0.5rem;
    color: white;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
}

.dropdown-hamburger {
    cursor: pointer;
}

.dropdown-content-hamburger {
    list-style: none;
    text-align: unset;
    font-family: OpenSans;
    font-size: 1em;
    text-transform: uppercase;
    margin: 0.5em;
    font-weight: bold;
    letter-spacing: 0.5rem;
}

.dropdown-content-hamburger li {
    margin: 0.5em;
}

.dropdown-content-hamburger.active {
    display: block;
}
.dropdown-content-hamburger.inactive {
    display: none;
}

.navbar ul {
    max-width: 825px !important;
}

.bussines-step-container, .logistics-technology-container, .production-step-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 40px;
    align-items: center;
    flex-direction: row;
}

.bussines-step-container {
    column-gap: 40px;
    row-gap: 40px;
}

.logistics-technology-container {
    column-gap: 40px;
    row-gap: 20px;
}

.production-step-container {
    column-gap: 20px;
    row-gap: 20px;
}

@media(max-width: 991px) {
    .production-step-container {
        flex-direction: column;
    }
}

.business_cont1{
    background-image: url(../../assets/bgImg/automation-business-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 65px);
}

.bussines-step {
    border-bottom: 4px solid #536bec;
    padding: 40px;
    position: relative;
    width: 200px;
}

.bussines-step:hover .arrow-down {
    animation: arrowDown .75s infinite linear;
}

@keyframes arrowDown {
    0% { transform: translateY(0); }
    25% { transform: translateY(-4px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(4px); }
    100% { transform: translateY(0); }
}

.bussines-step1:hover .business-counter1,
.bussines-step2:hover .business-counter2,
.bussines-step3:hover .business-counter3,
.bussines-step4:hover .business-counter4 {
    animation: counterDown .75s infinite linear;
}

@keyframes counterDown {
    0% { top:-10px; }
    25% { top:-14px; }
    50% { top:-10px; }
    75% { top: -6px; }
    100% { top:-10px; }
}

.bussines-step-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}

.business-counter {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
}

.business-counter1 {
    transition-delay: .2s !important;
}
.business-counter2 {
    transition-delay: .4s !important;
}
.business-counter3 {
    transition-delay: .6s !important;
}
.business-counter4 {
    transition-delay: .8s !important;
}

.logistics_cont1 {
    background-image: url(../../assets/bgImg/automation-logistics-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 65px);
}

.logistics-technology {
    border-bottom: 4px solid #536bec;
    position: relative;
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 2px 12px 1px #b8b8b857;
    border-top: 4px solid #536bec;
}

.logistics-technology:hover, .production-step:hover {
    transform: scale(1.05);
    box-shadow: -3px 2px 12px 3px #b8b8b857;
}

.logistics-technology-info {
    text-align: center;
}

.production_cont1 {
    background-image: url(../../assets/bgImg/automation-production1-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 65px);
}

.production-step-container:hover .arrow-right {
    animation: arrowRIght .75s infinite linear;
}

@keyframes arrowRIght {
    0% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    50% { transform: translateX(0); }
    75% { transform: translateX(4px); }
    100% { transform: translateX(0); }
}

.production-step-container:hover .arrow-down {
    animation: arrowDown .75s infinite linear;
}

@keyframes arrowDown {
    0% { transform: translateY(0); }
    25% { transform: translateY(-4px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(4px); }
    100% { transform: translateYss(0); }
}

.production-step {
    border-bottom: 4px solid #536bec;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 2px 12px 1px #b8b8b857;
    border-top: 4px solid #536bec;
    width: 60%;
}

.production-step-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    text-align: center;
}

.production-arrow-right {
    height: 40px;
}

.arrow-down {
    display: block;
}

.arrow-right {
    display: none;
}

.process-icon {
    filter: brightness(0.5);
}

@media(min-width: 992px) {
    .production-step {
        width: 240px;
    }

    .arrow-down {
        display: none;
    }
    
    .arrow-right {
        display: block;
    }
}

@media(min-width: 1200px) {
    .txt-procces {
        font-size: 20px;
    }
}